import React from 'react';
import type { ErrorModuleProps } from './interfaces';
import styles from './ErrorModule.module.scss';
import Container from '../Container/Container';
import { ContainerChildrenSpace } from '../Container/consts';
import Lottie from '../Lottie/Lottie';
import Headline from '../Headline/Headline';
import {
  HeadlineColor,
  HeadlineLevel,
  HeadlineAlign,
} from '../Headline/consts';
import RichText from '../RichText/RichText';
import Button from '../Button/Button';
import { ClickableAction } from '../Clickable/consts';
import { ButtonAlign } from '../Button/consts';
import reloadWindow from '@/helpers/reloadWindow';

const ErrorModule = ({
  animationData,
  handleEnterFrame,
  errorMessage,
  type = 'default',
}: ErrorModuleProps) => {
  return (
    <div>
      <Container flexCenter spaceBetweenChildren={ContainerChildrenSpace.Micro}>
        <Lottie
          className={styles.annimation}
          animationData={animationData}
          autoplay
          onEnterFrame={handleEnterFrame}
          name="404-animation"
        />
        <Headline
          headlineColor={HeadlineColor.Dark}
          level={HeadlineLevel.One}
          align={HeadlineAlign.Center}
          className={styles.headline}
        >
          Ooops!
        </Headline>

        <RichText className={styles.richtext} align="center">
          {errorMessage}
        </RichText>
        {type === 'default' && (
          <Button
            className={styles.button}
            actionType={ClickableAction.Linkintern}
            linkintern="/"
            align={ButtonAlign.Center}
          >
            Zurück zur Startseite
          </Button>
        )}

        {type === 'reload' && (
          <Button
            className={styles.button}
            actionType={ClickableAction.Custom}
            onClick={() => reloadWindow()}
            align={ButtonAlign.Center}
          >
            Erneut versuchen
          </Button>
        )}
      </Container>
    </div>
  );
};

export default ErrorModule;
